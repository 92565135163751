.skill-div {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left:0;
    transform: translate(-50%, -60%);
    width: 80vw;
    max-width: 900px;
    max-height: 700px;
    height: 70vh;
    color: #1e1e1e;
    background: #e1e1e1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    text-align: center;
    align-items: center;
    justify-items: center;
    padding: 20px 40px;
    margin: 0;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 0px 0px 40px #9399c9;
}

.skill {
    display: flex;
    flex-direction: column;
    height: 100%;
    align-items: center;
    /* justify-content: space-around; */
    gap: 20px;
}

.skill-img {
    box-shadow: 0px 0px 20px #9399c9;
    border-radius: 5px;
    display: block;
    margin-top: 20px;
    max-width: 100%;
    max-height: 50%;
    width: auto;
    height: auto;
}

.skill-content {
    max-width: 800px;

}

.skill-title {
    font-size: 1.6rem;
    font-style: italic;
    color: #3d284c;
    font-weight: bold;

}

.skill-inf {
    font-size: 1.1rem;
    overflow: auto;
}

