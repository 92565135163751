.landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: #101231;
    color: #e1e1e1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #101231;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


.option {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #e1e1e1;
    opacity: 1;
    text-align: center;
    justify-content: center;
    height: 250px; 
    box-shadow: 0px 0px 10px #48cae4;
    padding: 20px 15px;
    justify-content: space-evenly;
    align-items: center;
}

.option h4 {
    text-decoration: none;
    font-size: 2rem;
}

.option p {
    text-decoration: none;
    font-size: 1.5rem;
}

.option i {
    text-decoration: none;
    box-sizing: border-box;
    font-size: 2rem;
    padding: 0px 10px;
}
.placeholder-overlay{
    width: 150px;
    height: 150px;
}
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;600&display=swap");

:root {
	--dark-grey: #151922;
	--light-grey: #ccc;
	--med-grey: #ddd;
	--active: #4a4a4a;

  --border: #48cae4;
  --accent: #ade8f4;
  --size: 100px;
  --border-size: 3px;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}






.circle {
	--border: #48cae4;
	--size: 100px;
	--border-size: 3px;

	position: relative;
	display: flex;
	border: var(--border-size) solid var(--border);
	width: var(--size);
	height: var(--size);
	border-radius: 50%;
	border-bottom-color: transparent;
	background-color: transparent;
	margin: 20px 0;
	
	padding: 0;
	animation: rotate 1.5s linear infinite;

}





@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
