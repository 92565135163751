/* About */
.about-div {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    /* width: 100%; */
    min-width: 800px;
    min-height: 600px;
    max-width: 1000px;
    max-height: 600px;

    color: #1e1e1e;
    background: #e1e1e1;

    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 0px 0px 40px #9399c9;
    padding: 10px;
}

.about {
    flex:1;
    box-sizing: border-box;
    display: flex;
    column-gap: 10px;
    text-align: center;
    align-items: center;
    justify-items: center;
    height: 90%;
    
}



.about-img {
    flex-grow: 1;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    row-gap: 10px;
    text-align: center;
    box-shadow: 0px 0px 10px #1e1e1e;
    border-radius: 5px;

}

.about-img>img {
    background: transparent;
    border-radius: 10px;
    display: block;
    max-width: 100%;
    height: 400px;
    width: auto;
    height: auto;
}

.about-inf {

flex-grow: 2; 
    box-sizing: border-box;
    margin: 0;
    padding: 40px 0px 0px 0px;
    text-align: left;
    font-size: 1.4rem;
    height: 100%;
    display: grid;
    justify-content: space-between;
    box-shadow: 0px 0px 10px #1e1e1e;
    border-radius: 5px;
    padding: 5px;
    overflow-y: scroll; overflow-x: hidden;
}

.about-contact {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    margin: 0;
    font-size: 1rem;
    font-weight: bold;

}

.about-contact p {
    gap: 2px;
    padding: 0px 4px;
    display: flex;
    line-height: 100%;
}

.resume-btn {
    box-sizing: border-box;
    color: #ccc;
    background: #3d284c;
    border: none;
    border-radius: 5px;
    height: 40px;
    font-size: 1.5rem;
    width: 180px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    border: 1px solid;
}

.resume-btn:hover {
    color: #fff;
}


.con-p{display: flex;justify-content: center;}
@media screen and (max-width: 800px) {
    .about-div {
        display: flex;
        flex-direction: column;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        min-width: 300px;
        min-height: none;
        width: 95vw;
        height: 98vh;
        z-index: 1;
        border-radius: 10px;
        box-shadow: 0px 0px 5px #9399c9;
    }

    .about {
        display: flex;
        row-gap: 5px;
        margin: 0;
        padding: 0;
        width: 95%;
        height: 98%;
        flex-direction: column;
        justify-content: space-evenly;
    }

    .about-img {
        height: 48%;
        row-gap: 5px;
    }

    .about-inf {
        width: 100%;
        height: 48%;
        display: flex;
        flex-direction: column;
        text-align: center;
        font-size: 1.4rem;
        box-shadow: 0px 0px 5px #1e1e1e;
        overflow: auto;

    }
}

.invisible {
    display: none;
}
