html{font-size: 16px,}

p{
    font-size: 1rem;
    margin-top: 0px;
}
h1{
    font-size:1.7rem ;
}
h2{ font-size:1.3rem;
    margin-bottom: 2px;}
h1,h2{text-transform: capitalize;}
* {
    box-sizing: border-box;
}

#root {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;

}




/* Loading */
.overLay {
    box-sizing: border-box;
    color: green;
    background: #e1e1e1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: grid;
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-items: center;
    font-size: 40px;
    padding: 10px;
    z-index: 1;
    scale: 1;
    animation: scaleloading 5s ease-in-out infinite alternate;


}

/* Projects */

.projects-div {
    box-sizing: border-box;
    color: #1e1e1e;
    background: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    max-width: 900px;
    max-height: 700px;
    height: 80vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    text-align: center;
    align-items: center;
    justify-items: center;
    font-size: 40px;
    padding: 10px;
    z-index: 1;
    border-radius: 20px;
    box-shadow: 0px 0px 40px rgb(230, 231, 225);
}



.slidshow-image {
    border-radius: 10px;
    box-shadow: 5px 5px 10px black;
    display: block;
    margin: 0 auto 40px auto;
    max-width: 100%;
    max-height: 50%;
    width: auto;
    height: auto;
}

.next-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    font-size: 3rem;
    font-weight: 20;
    height: 200px;
    background: #000000aa;
    color: orange;
    border: none;


}

.prev-btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    left: 0;
    font-size: 3rem;
    font-weight: 20;
    height: 200px;
    background: #000000aa;
    color: orange;
    border: none;
}

.slidshow {
    margin-top: 40px;
    position: relative;
    overflow: hidden;

}

.nextSlide {
    position: absolute;
    transform: translateX(-100vw)
}

.lastSlide {
    position: absolute;
    transform: translateX(100vw)
}



.slideshow-title {
    font-size: 1.5rem;
}

.slideshow-inf {
    font-size: 1rem;
}

.slideshow-url {
    width: 100px;
    background: #3d284c;
    text-decoration: none;
    cursor: pointer;
    color: #e1e1e1;
    font-size: 1rem;
    padding: 10px;
    border-radius: 5px;
}

.slideshow-url:hover {
    color: #fff;
    border: #1e1e1e;
}



.close-btn {
    box-sizing: border-box;
    display: grid;
    align-items: center;
    padding: 5px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-50%, 50%);
    color: #1e1e1e;
    background: none;
    font-size: 3rem;
    border: none;
    line-height: 100%;
    cursor: pointer;
    font-size: 2rem;

}

.projects-video {
    align-self: center;
    margin: 0 auto;
    box-shadow: 5px 5px 10px black;
}



/* Navigation */

.navigation {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}



.navigation-btn {
    flex: 1 1 0;
    width: 0;
    max-width: 100px;
    margin-top: 5px;
    font-size: 1.3rem;
    color: #cccccc;
    background: #222;
    border: none;
    height: 40px;
    border-radius: 5px;
}

.navigation-btn:hover {
    color: #fff;
    box-shadow: 0px 0px 5px #cccccc;
    cursor: pointer;
}

.btn {
    cursor: pointer;
}
.close-btn:hover{
    scale: 1.2;
    color:red;
}

.landingPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background: black;
    color: e1e1e1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: #e1e1e1;
}


.option {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    color: #e1e1e1;
    opacity: 1;
    text-align: center;
    justify-content: center;
    height: 250px; 
    box-shadow: 0px 0px 10px #e1e1e1;
    padding: 20px 15px;
    justify-content: space-evenly;
    align-items: center;
}

.option h4 {
    text-decoration: none;
    font-size: 2rem;
}

.option p {
    text-decoration: none;
    font-size: 1.5rem;
}

.option i {
    text-decoration: none;
    box-sizing: border-box;
    font-size: 2rem;
    padding: 0px 10px;
}
.placeholder-overlay{
    width: 150px;
    height: 150px;
}

.invisible {
    display: none;
}